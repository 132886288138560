import React, { useCallback, useEffect, useRef } from 'react';
import { CCol, CContainer, CFormCheck, CRow } from '@coreui/react';
import InputIcon from '../../../../components/InputIcon';
import SelectIcon from '../../../../components/SelectIcon';
import { useSwiper } from 'swiper/react';
import classnames from 'classnames';
import ModalLimitContent from 'src/components/ModalLimitContent';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { checkPhoneExit } from 'src/request';
import { useNewsletter } from 'src/context/NewsLetterContext';

const Step1 = ({
  refForm,
  page = {
    goToLogin: () => {},
    goToSignUp: () => {},
  },
}) => {
  const swiper = useSwiper();
  const [seeConfirmPassword, setSeeConfirmPassword] = React.useState(false);
  const [seePassword, setSeePassword] = React.useState(false);
  const [stateBtn, setStateBtn] = React.useState(false);
  const [isSend, setIsSend] = React.useState(false);
  const [tel, setTel] = React.useState('');

  const [passwordValid, setPasswordValid] = React.useState(true);
  const [cguCheck, setCGUCheck] = React.useState(false);
  const [cgvCheck, setCGVCheck] = React.useState(false);
  const [showModalError, setShowmodalError] = React.useState(false);
  const [errorText, setErrorText] = React.useState(
    "En cochant les cases ci-dessous, vous confirmez avoir lu, compris et accepté nos Conditions Générales de Vente (CGV), nos Conditions Générales d'Utilisation (CGU) ainsi que notre Politique de Confidentialité. En acceptant ces termes, vous reconnaissez également notre engagement à respecter et protéger votre vie privée tout en utilisant nos services.",
  );
  const [newsletterCheck, setNewsletterCheck] = React.useState(false);
  const { isNewsletterChecked, setIsNewsletterChecked } = useNewsletter();

  const selectElement = document.querySelector('.PhoneInputCountrySelect');
  if (selectElement) selectElement.style.display = isSend ? 'none' : '';

  const isValidStep = useCallback(() => {
    let isValid = true;
    let pwdValid = true;
    if (refForm.firstName?.current?.value?.length < 1) {
      isValid = false;
    }
    if (refForm.name?.current?.value?.length < 1) {
      isValid = false;
    }
    if (
      refForm.password?.current?.value?.length < 8 &&
      refForm.password?.current?.value?.length > 3
    ) {
      pwdValid = false;
    }
    if (
      refForm.confirmPassword?.current?.value?.length < 8 &&
      refForm.password?.current?.value?.length > 3
    ) {
      pwdValid = false;
    }
    if (refForm.iAm?.current?.value < 1) {
      isValid = false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(refForm.email?.current?.value) === false) {
      isValid = false;
    }

    setPasswordValid(pwdValid);
    setStateBtn(isValid);
  }, [refForm, tel, stateBtn, passwordValid]);

  const handleNext = useCallback(async () => {
    if (tel == '') {
      setShowmodalError(true);
      return setErrorText('Veuillez saisir un n° de téléphone valide');
    }

    if (refForm.password?.current?.value !== refForm.confirmPassword?.current?.value) {
      setShowmodalError(true);
      return setErrorText('Les deux mot de passe doivent être identiques');
    }

    let obj = {
      phone: tel,
      email: refForm?.email?.current?.value,
    };

    const res = await checkPhoneExit(obj);
    if (!res?.success) {
      setShowmodalError(true);
      setErrorText(res?.message);
      return;
    }

    if (!cguCheck || !cgvCheck) {
      return setShowmodalError(true);
    }
    swiper.slideNext();
  }, [cguCheck, cgvCheck, tel, refForm]);

  return (
    <>
      <p className="textImput">Complétez vos informations personnelles</p>
      <CContainer fluid className={'marginRight15'}>
        <CRow>
          <CCol className={'paddingLeft0'}>
            <InputIcon
              onChange={() => {
                isValidStep();
              }}
              refCustom={refForm.firstName}
              iconLeft={'smileyFace.png'}
              placeholder={'Votre prénom'}
            />
          </CCol>
          <CCol className={'paddingLeft0'}>
            <InputIcon
              onChange={() => {
                isValidStep();
              }}
              refCustom={refForm.lastName}
              iconLeft={'smileyFace.png'}
              placeholder={'Votre nom'}
            />
          </CCol>
        </CRow>
        <CRow className={'marginTop40'}>
          {/* <p>Ajouter un numéro de téléphone pour confirmer votre identité</p> */}
          <CCol>
            <PhoneInput
              ref={refForm.tel}
              placeholder="Saisissez le numéro de téléphone"
              value={tel}
              defaultCountry="FR"
              onChange={(value) => setTel(value?.replace(/\s+/g, ''))} // Suppression des espaces
              //disabled={isSend}
            />
          </CCol>
          <CCol className={'paddingLeft0'}>
            <SelectIcon
              refCustom={refForm.iAm}
              onChange={() => {
                isValidStep();
              }}
              iconLeft={'smileyFace.png'}
              iconRight={'chevronIcon.png'}
            >
              <option value="0">Je suis...</option>
              <option value="1">Un (e) simple perfumista</option>
              <option value="2">Un (e) étudiant (e) en école de parfumerie</option>
              <option value="3">Un (e) parfumeur</option>
            </SelectIcon>
          </CCol>
        </CRow>

        <CRow className={'marginTop40'}>
          <CCol className={'paddingLeft0'}>
            <InputIcon
              refCustom={refForm.email}
              type={'email'}
              iconLeft={'iconMail.png'}
              placeholder={'Votre adresse mail'}
              onChange={() => {
                isValidStep();
              }}
            ></InputIcon>
          </CCol>
        </CRow>

        <CRow className={'marginTop40'}>
          <CCol className={'paddingLeft0'}>
            <InputIcon
              refCustom={refForm.password}
              iconLeft={'iconPadlock.png'}
              iconRight={'iconSeePassword.png'}
              placeholder={'Votre mot de passe'}
              iconRightOnclick={() => setSeePassword((bool) => !bool)}
              type={seePassword ? undefined : 'password'}
              onChange={() => {
                isValidStep();
              }}
              className="disable-zoom"
            ></InputIcon>
          </CCol>
          <CCol className={'paddingLeft0'}>
            <InputIcon
              refCustom={refForm.confirmPassword}
              placeholder={'Confirmation mot de passe'}
              iconLeft={'iconPadlock.png'}
              iconRight={'iconSeePassword.png'}
              type={seeConfirmPassword ? undefined : 'password'}
              onChange={() => {
                isValidStep();
              }}
              iconRightOnclick={() => setSeeConfirmPassword((bool) => !bool)}
              className="disable-zoom"
            ></InputIcon>
          </CCol>
        </CRow>

        <CRow className="marginTop10 paddingLeft10">
          <CCol xs={12} className={'paddingLeft0'}>
            <CFormCheck
              type="checkbox"
              id="cgu"
              checked={cguCheck}
              onChange={() => setCGUCheck(!cguCheck)}
              label={
                <div>
                  {"J'accepte "}
                  <span
                    style={{ textDecorationLine: 'underline', cursor: 'pointer', color: '#22A18A' }}
                    onClick={() =>
                      window.open(
                        'https://www.baladesparfumees.com/conditions-generales-utilisation',
                        '_blank',
                      )
                    }
                  >
                    {" les conditions générales d'utilisation "}
                  </span>
                  et
                  <span
                    style={{ textDecorationLine: 'underline', cursor: 'pointer', color: '#22A18A' }}
                    onClick={() =>
                      window.open(
                        'https://www.baladesparfumees.com/conditions-generales-utilisation/',
                        '_blank',
                      )
                    }
                  >
                    {' la politique de confidentialité'}
                  </span>
                </div>
              }
            />
            <input ref={refForm.cguCheck} value={cguCheck} style={{ display: 'none' }} />
          </CCol>
          <CCol xs={12} className={'paddingLeft0'}>
            <CFormCheck
              type="checkbox"
              checked={cgvCheck}
              onChange={() => setCGVCheck(!cgvCheck)}
              id="cgv"
              label={
                <div>
                  {"J'accepte "}
                  <span
                    style={{ textDecorationLine: 'underline', cursor: 'pointer', color: '#22A18A' }}
                    onClick={() =>
                      window.open(
                        'https://www.baladesparfumees.com/conditions-generales-de-vente',
                        '_blank',
                      )
                    }
                  >
                    {'les conditions générales de vente'}
                  </span>
                </div>
              }
            />
            <input ref={refForm.cgvCheck} value={cgvCheck} style={{ display: 'none' }} />
          </CCol>
          <CCol xs={12} className={'paddingLeft0'}>
            <CFormCheck
              type="checkbox"
              checked={isNewsletterChecked}
              onChange={() => setIsNewsletterChecked(!isNewsletterChecked)}
              id="newsletter"
              label={
                <div>
                  {"Je souhaite m'abonner à la newsletter pour recevoir les dernières actualités"}
                </div>
              }
            />
            <input
              ref={refForm.newsletterCheck}
              value={isNewsletterChecked}
              style={{ display: 'none' }}
            />
          </CCol>
        </CRow>
        {!passwordValid && (
          <span style={{ fontSize: 12 }}>
            Votre mot de passe doit contenir au moins 8 caractères.
          </span>
        )}
        <CRow className={'marginTop40'}>
          <CCol className={'paddingLeft0'}>
            <button
              disabled={!stateBtn}
              className={classnames('buttonNext', stateBtn ? '' : 'disabled')}
              onClick={handleNext}
            >
              Suivant
            </button>
          </CCol>
        </CRow>
      </CContainer>
      <p className={'textBottom'}>
        Vous avez déjà un compte ?{' '}
        <button onClick={() => page.goToLogin()} className={'textBottomLink'}>
          Connectez-vous pour y accéder !
        </button>
      </p>
      <>
        <ModalLimitContent
          visible={showModalError}
          showButton={false}
          content={errorText}
          onClose={() => {
            setShowmodalError(false);
          }}
        />
      </>
    </>
  );
};

export default Step1;

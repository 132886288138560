import React, { useState } from 'react';
import perfumesComponents from 'src/assets/images/login/perfumes_components.png';
import textLogoBaladeParfume from 'src/assets/images/login/textLogoBaladeParfume.png';
import eventail from 'src/assets/images/login/eventail.jpg';

import 'swiper/css';
import InputIcon from '../../../../components/InputIcon';
import { useNavigate } from 'react-router-dom';
import { CAlert, CCol, CContainer, CRow, CSpinner } from '@coreui/react';
import { useSwiper } from 'swiper/react';
import axios from 'axios';
import { authLogin, lostPassword } from '../../../../request';
import PasswordResetModal from '../PasswordResetModal';
const Login = () => {
  const swiper = useSwiper();
  const navigate = useNavigate();
  // all ref FormInput centralized //
  let page = {
    goToLogin: () => swiper.slideNext(),
    goToSignUp: () => swiper.slidePrev(),
  };
  // state
  let refPassword = React.useRef();
  let refeMail = React.useRef();
  const [seePassword, setSeePassword] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [load, setLoad] = React.useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleSubmit = (username, password) => {
    setLoad(true);
    authLogin({ username, password })
      .then((res) => {
        localStorage.setItem('token', res.data.token);
        navigate('/dashboard');
        setLoad(false);
      })
      .catch((err) => {
        if (err?.response?.data?.code === 401) {
          setErrorMessage(
            "Nous n'avons pas pu vous connecter. Vérifiez vos informations d'identification et réessayez",
          );
          setLoad(false);
        } else if (err?.response?.data?.code === 500) {
          setErrorMessage('Une erreur est survenue.');
          setLoad(false);
        }
      });
  };

  return (
    <div className={'login-form-container'}>
      <div className={'containerImageFormMobile'}>
        <img
          className={'imgFormMobile'}
          draggable={false}
          src={eventail}
          alt={'A little round mirror with in it reflects a perfume held by a person.'}
        />
      </div>
      <div className={'containerImageForm'}>
        <img
          className={'imgForm'}
          draggable={false}
          src={perfumesComponents}
          alt={'A little round mirror with in it reflects a perfume held by a person.'}
        />
      </div>
      <div className={'formInputLogin'}>
        <img
          className={'textLogoBaladeParfume'}
          draggable={false}
          src={textLogoBaladeParfume}
          alt={'Logo brand BaladeParfume with text BaladeParfume'}
        />
        <div className={'containerRegister'}>
          <p className={'titreRegister'}>Me connecter</p>
          <p className={'textOverProgressBar'}>Pour accéder à mon carnet olfactif digital</p>
        </div>
        <div className="containerRegister containerInput">
          <CRow className={'marginTop40'}>
            <CCol className={'paddingLeft0'}>
              <InputIcon
                refCustom={refeMail}
                onChange={() => {
                  setErrorMessage('');
                }}
                type={'email'}
                iconLeft={'iconMail.png'}
                placeholder={'Votre adresse mail'}
              ></InputIcon>
            </CCol>
          </CRow>
          <CRow className={'marginTop40'}>
            <CCol className={'paddingLeft0'}>
              <InputIcon
                refCustom={refPassword}
                onChange={() => {
                  setErrorMessage('');
                }}
                iconLeft={'iconPadlock.png'}
                iconRight={'iconSeePassword.png'}
                placeholder={'Votre mot de passe'}
                iconRightOnclick={() => setSeePassword((bool) => !bool)}
                type={seePassword ? undefined : 'password'}
              ></InputIcon>
            </CCol>
          </CRow>
          <p style={{ textAlign: 'right' }}>
            <button onClick={openModal} className={'textBottomLink'}>
              Mot de passe oublié ?
            </button>
          </p>
          <CRow className={'marginTop10'}>
            <CCol className={'paddingLeft0'}>
              <CSpinner
                color="primary"
                style={{
                  visibility: load ? 'visible' : 'hidden',
                }}
              />

              <button
                onClick={() => handleSubmit(refeMail.current.value, refPassword.current.value)}
                className={'swiper-button-next buttonNext sizeButtonLogin'}
                style={{
                  width: 'fit-content',
                  paddingLeft: 10,
                  paddingRight: 10,
                  backgroundColor: '#22A18A',
                  color: 'white',
                  float: 'right',
                  borderRadius: 8,
                  height: 48,
                  fontSize: 19,
                  position: 'relative',
                  marginBottom: '1vh',
                  textAlign: 'center',
                  visibility: load ? 'hidden' : 'visible',
                }}
              >
                Accéder à mon carnet olfactif
              </button>
            </CCol>
            <p style={{ textAlign: 'center', marginTop: 60 }}>
              Pas encore inscrit(e) ?{' '}
              <button
                onClick={() => page.goToLogin()}
                className={'textBottomLink bold'}
                style={{ fontWeight: 'bold' }}
              >
                {'Inscrivez-vous au carnet olfactif digital dès maintenant'}
              </button>
            </p>
            <p style={{ textAlign: 'center', marginTop: 10 }}>
              {`Qu’est-ce que le carnet olfactif digital ?`}{' '}
              <a
                href="https://landing.moncarnetolfactif.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className={'textBottomLink bold'} style={{ fontWeight: 'bold' }}>
                  En savoir plus
                </button>
              </a>
            </p>
            {/* Nouveau bouton ajouté ici */}
            {/*    <p style={{ textAlign: 'center', marginTop: 20 }}>
              <a href="https://landing.moncarnetolfactif.com/" target="_blank" rel="noopener noreferrer">
                <button
                  className={'swiper-button-next buttonNext sizeButtonLogin'}
                  style={{
                    width: 'fit-content',
                    paddingLeft: 10,
                    paddingRight: 10,
                    backgroundColor: '#22A18A',
                    color: 'white',
                    borderRadius: 8,
                    height: 48,
                    fontSize: 19,
                    textAlign: 'center',
                  }}
                >
                  En savoir plus sur Mon carnet olfactif
                </button>
              </a>
            </p> */}
          </CRow>
          <PasswordResetModal visible={modalVisible} onClose={closeModal} />
          {errorMessage && <CAlert color="error">{errorMessage}</CAlert>}
        </div>
      </div>
    </div>
  );
};

export default Login;

import React from 'react';

import ProgressBar from '@ramonak/react-progress-bar';
import perfumeMirror from 'src/assets/images/login/perfume_mirror.png';
import textLogoBaladeParfume from 'src/assets/images/login/textLogoBaladeParfume.png';

// Import Swiper React components
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import { CContainer } from '@coreui/react';

const startProgresBar = 3;

const SignUp = () => {
  const swiper = useSwiper();

  let page = {
    goToLogin: () => swiper.slidePrev(),
    goToSignUp: () => swiper.slideNext(),
  };
  // all ref FormInput centralized //
  let refForm = {
    step1: {
      firstName: React.useRef(null),
      lastName: React.useRef(null),
      password: React.useRef(null),
      iAm: React.useRef(null),
      confirmPassword: React.useRef(null),
      email: React.useRef(null),
      cgvCheck: React.useState(),
      cguCheck: React.useRef(false),
      tel: React.useRef(null),
    },
    step2: {
      pseudo: React.useRef(null),
      mySymbolicPerfumeIs: React.useRef(null),
      iWantUseThisSiteFor: React.useRef(null),
      myOpinionOfMyCollectionsIs: React.useRef(null),
      theSmellsOfMyChildhoodIs: React.useRef(null),
    },
    step3: {
      tel: React.useRef(null),
      codeValidateAccount: React.useRef(null),
      email: React.useRef(null),
    },
    step4: {
      email: React.useRef(null),
    },
  };
  // etat step //
  const [progress, setProgress] = React.useState(startProgresBar);
  const [totalStep, setTotalStep] = React.useState(0);
  const [step, setStep] = React.useState(1);

  const changeStepUpdateProgressBar = (step, totalStep) => {
    let result = (step / totalStep) * 100;
    setProgress(step === 1 ? startProgresBar : result);
    setStep(step);
  };
  return (
    <div className={'login-form-container'}>
      <div className={'containerImageForm'}>
        <img
          className={'imgForm'}
          draggable={false}
          src={perfumeMirror}
          alt={'A little round mirror with in it reflects a perfume held by a person.'}
        />
      </div>
      <div className={'formInputLogin'}>
        <img
          className={'textLogoBaladeParfume'}
          draggable={false}
          src={textLogoBaladeParfume}
          alt={'Logo brand BaladeParfume with text BaladeParfume'}
        />
        <div className={'containerRegister'}>
          <p className={'titreRegister'}>Inscription</p>
          <p className={'textOverProgressBar'}>Commencez à écrire votre histoire olfactive</p>
          <div className={'containerProgressBar'}>
            <ProgressBar
              completed={progress}
              barContainerClassName="barBackground"
              height={'8px'}
              isLabelVisible={false}
              bgColor={'#22A18A'}
            />
            <span className={'countStep'}>
              {step} / {totalStep ? totalStep : '-'}
            </span>
          </div>
        </div>
        <div className=" ">
          <Swiper
            allowTouchMove={false}
            speed={440}
            onSlideChange={(swiper) =>
              changeStepUpdateProgressBar(swiper.activeIndex + 1, swiper.slides.length)
            }
            onSwiper={(swiper) => setTotalStep(swiper.slides.length)}
          >
            {/*  <SwiperSlide>
              <Step3 page={page} refForm={refForm} />
            </SwiperSlide> */}
            <SwiperSlide>
              <CContainer
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  backgroundColor: '#ffff',
                }}
              >
                <Step1 page={page} refForm={refForm.step1} />
              </CContainer>
            </SwiperSlide>
            {/* <SwiperSlide>
              <Step2 page={page} refForm={refForm.step2} />
            </SwiperSlide> */}
            <SwiperSlide>
              <CContainer style={{ display: 'flex', flex: 1, backgroundColor: '#ffff' }}>
                <Step3 page={page} refForm={refForm} />
              </CContainer>
            </SwiperSlide>
            <SwiperSlide>
              <Step4 refForm={refForm} />
            </SwiperSlide>
            {/*  <SwiperSlide>
              <Step5 />
            </SwiperSlide> */}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default SignUp;

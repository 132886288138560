import React, { createContext, useState, useContext } from 'react';

const NewsletterContext = createContext();

export const NewsletterProvider = ({ children }) => {
  const [isNewsletterChecked, setIsNewsletterChecked] = useState(false);

  return (
    <NewsletterContext.Provider value={{ isNewsletterChecked, setIsNewsletterChecked }}>
      {children}
    </NewsletterContext.Provider>
  );
};

export const useNewsletter = () => useContext(NewsletterContext);

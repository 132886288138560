import { CAlert, CCol, CContainer, CRow, CSpinner } from '@coreui/react';
import React, { useEffect } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { authLogin, checkPhoneExit, generateCode, register, verifyCode } from 'src/request';
import { useSwiper } from 'swiper/react';
import CodeInput from '../../../../components/CodeInput';
let intervalId = null;

function getAllFormData(refForm) {
  const formData = {};
  for (const stepKey in refForm) {
    if (Object.prototype.hasOwnProperty.call(refForm, stepKey)) {
      if (!formData.hasOwnProperty(stepKey)) {
        formData[stepKey] = {};
      }

      for (const keyProperty in refForm[stepKey]) {
        if (Object.prototype.hasOwnProperty.call(refForm[stepKey], keyProperty)) {
          const ref = refForm[stepKey][keyProperty];
          formData[stepKey][keyProperty] = ref && ref.current ? ref.current.value : '';
        }
      }
    }
  }

  return formData;
}
const Step2 = ({
  refForm,
  page = {
    goToLogin: () => {},
    goToSignUp: () => {},
  },
}) => {
  const swiper = useSwiper();
  const [valueCode, setValueCode] = React.useState('');
  const [tel, setTel] = React.useState('');
  const [isSend, setIsSend] = React.useState(false);
  const [timeSend, setTimeSend] = React.useState(0);
  const [next, setNext] = React.useState(false);
  const [error, setError] = React.useState('');
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoad] = React.useState(false);

  const selectElement = document.querySelector('.PhoneInputCountrySelect');
  if (selectElement) selectElement.style.display = isSend ? 'none' : '';

  const mutationRegister = useMutation(register, {
    onSuccess: (res) => {
      console.log(' *** res ***', res);
    },
    onError: (err) => {},
  });

  const handleValidCode = async () => {
    setError('');
    let formData = getAllFormData(refForm);
    const data = {
      email: formData?.step1?.email,
      lastName: formData?.step1?.lastName,
      firstName: formData?.step1?.firstName,
      phone: formData?.step1?.tel,
      password: formData?.step1?.password,
      cgvAccepted: formData?.step1?.cgvCheck === 'true' ? true : false,
      cguAccepted: formData?.step1?.cguCheck === 'true' ? true : false,
      politicsAccpeted: formData?.step1?.cguCheck === 'true' ? true : false,
      iAm: formData?.step1?.iAm,
    };

    try {
      const resCheckCode = await verifyCode({
        code: valueCode,
      });

      if (resCheckCode?.data?.succes) {
        /* if (true) { */
        try {
          const res = await register(data);
          authLogin({ username: formData?.step1?.email, password: formData?.step1?.password })
            .then((res) => {
              localStorage.setItem('token', res.data.token);
              if (res?.data?.token) {
                swiper.slideNext();
              }
              setLoad(false);
            })
            .catch((err) => {
              if (err?.response?.data?.code === 401) {
                setError(
                  "Nous n'avons pas pu vous connecter. Vérifiez vos informations d'identification et réessayez",
                );
                setLoad(false);
              } else if (err?.response?.data?.code === 500) {
                setError('Une erreur est survenue.');
                setLoad(false);
              }
            });
        } catch (error) {
          if (error?.response?.data?.error) {
            setVisible(!visible);
            setError(error?.response?.data?.message);
          }
        }
      }
    } catch (error) {
      if (error?.response?.data?.message?.includes('Code has expired')) {
        setVisible(!visible);
        setError('Le code a expiré veuillez réessayer');
      }
      if (error?.response?.data?.message?.includes('Code not found')) {
        setVisible(!visible);
        setError('Le code saisi est incorrect');
        toast(error?.response?.data.message);
      }
    }
  };

  /*   const checkEmail = async () => {
    let formData = getAllFormData(refForm);
    try {
      const res = checkPhoneExit({
        phone: tel,
        email: formData?.step1?.email,
      });
    } catch (error) {}
  }; */

  const handleGenerateCode = async () => {
    let formData = getAllFormData(refForm);
    if (formData?.step1?.email)
      try {
        setLoad(true);
        const res = await generateCode({
          tel: formData?.step1?.tel,
          email: formData?.step1?.email,
        });
        if (res?.data.success) {
        }
      } catch (error) {
      } finally {
        setLoad(false);
      }
  };

  useEffect(() => {
    handleGenerateCode();
  }, [refForm]);

  const handleSendCode = async () => {
    /*  setIsSend(true);
    return; */
    let formData = getAllFormData(refForm);
    const res = await checkPhoneExit({
      phone: formData?.step1?.tel,
      email: formData?.step1?.email,
    });
    if (!res?.success) {
      setVisible(!visible);
      setError(res?.message);
      return;
    }

    setError('');
    if (!isSend) {
      handleGenerateCode();
    }

    if (timeSend === 0) {
      setIsSend(!isSend);
      if (isSend === false) {
        setTimeSend(30);
        setInterval(() => {
          intervalId = setTimeSend((time) => {
            if (time === 0) {
              clearInterval(intervalId);
              return 0;
            }
            return time - 1;
          });
        }, 1100);
      }
    } else if (isSend) {
      setIsSend(false);
    }
  };

  const resendCode = async () => {
    setError('');
    handleGenerateCode();
  };

  const handleTestRegister = async () => {
    let formData = getAllFormData(refForm);
    const data = {
      email: formData?.step1?.email,
      lastName: formData?.step1?.lastName,
      firstName: formData?.step1?.firstName,
      phone: '+262692394930',
      password: formData?.step1?.password,
      cgvAccepted: formData?.step1?.cgvCheck === 'true' ? true : false,
      cguAccepted: formData?.step1?.cguCheck === 'true' ? true : false,
      politicsAccpeted: formData?.step1?.cguCheck === 'true' ? true : false,
      iAm: formData?.step1?.iAm,
    };

    try {
      const res = await register(data);
      authLogin({ username: formData?.step1?.email, password: formData?.step1?.password })
        .then((res) => {
          localStorage.setItem('token', res.data.token);
          localStorage.setItem('email', formData?.step1?.email);
          if (res?.data?.token) {
            swiper.slideNext();
          }
          setLoad(false);
        })
        .catch((err) => {
          if (err?.response?.data?.code === 401) {
            setError(
              "Nous n'avons pas pu vous connecter. Vérifiez vos informations d'identification et réessayez",
            );
            setLoad(false);
          } else if (err?.response?.data?.code === 500) {
            setError('Une erreur est survenue.');
            setLoad(false);
          }
        });
    } catch (error) {
      if (error?.response?.data?.error) {
        setVisible(!visible);
        setError(error?.response?.data?.message);
      }
    }
  };

  return (
    <>
      <CContainer fluid>
        <p className="textImput">Confirmez votre compte</p>
        <CRow>
          <CCol>
            <p>Entrez le code, reçu par e-mail, pour confirmer votre compte.</p>
            <p>Vous n&apos;êtes plus qu&apos;à quelques clics de votre carnet olfactif digital !</p>
          </CCol>
        </CRow>
        <span style={{ fontStyle: 'italic', fontSize: 14 }}>
          {
            "(vous n'avez pas reçu le mail de confirmation ? N'hésitez pas à consulter vos mails indésirables. En cas de problèmes, contactez-nous à baladesparfumees@gmail.com)"
          }
        </span>
        <CAlert color="danger" dismissible visible={visible} onClose={() => setVisible(false)}>
          {error}
        </CAlert>
        <CRow className={'marginTop20'}>
          {/*  <p>Ajouter un numéro de téléphone pour confirmer votre identité</p>
          <CCol>
            <PhoneInput
              placeholder="Saisissez le numéro de téléphone"
              value={tel}
              defaultCountry="FR"
              onChange={setTel}
              //disabled={isSend}
            />
          </CCol> */}
          {/*  <CCol>
            <button
              className="send"
              disabled={
                tel === undefined ||
                (tel.includes('+33') && tel.length <= 11) ||
                (!tel.includes('+33') && tel.length <= 10)
              }
              onClick={() => {
                handleSendCode();
              }}
            >
              {isSend ? 'MODIFIER' : timeSend > 0 ? `${timeSend} SECONDES` : 'Envoyer'}
            </button>
          </CCol> */}
        </CRow>
        <CRow className={'marginTop40'}>
          <CCol /* className={`tes ${isSend ? '' : 'hidden'}`} */ className={`tes`}>
            <input value={valueCode} ref={refForm.step3.codeValidateAccount} type="hidden" />
            {loading && (
              <CSpinner
                component="span"
                size="sm"
                aria-hidden="true"
                style={{ marginBottom: 20 }}
              />
            )}
            <CodeInput
              onChange={(newValue) => {
                if (JSON.stringify(newValue) !== JSON.stringify(valueCode)) setValueCode(newValue);
                console.log(newValue);
              }}
              fields={6}
              onClick={handleValidCode}
            ></CodeInput>
          </CCol>
        </CRow>
        <CRow>
          <button
            //className="send"
            style={{
              marginTop: 20,
              backgroundColor: 'transparent',
              textDecorationLine: 'underline',
              color: '#22A18A',
            }}
            /*  disabled={
              (isSend === false && timeSend > 0) ||
              tel === undefined ||
              (tel.includes('+33') && tel.length <= 11) ||
              (!tel.includes('+33') && tel.length <= 10)
            } */
            onClick={() => {
              resendCode();
            }}
          >
            {'Renvoyer un nouveau code'}
          </button>
        </CRow>
        <CRow className={'marginTop40'}>
          <CCol>
            <button
              className="buttonPrev"
              onClick={() => {
                swiper.slidePrev();
              }}
            >
              Retour
            </button>
            <button
              className="buttonNext"
              disabled={true}
              onClick={() => {
                swiper.slideNext();
              }}
            >
              Suivant
            </button>
            {/*  <button
              className="buttonNext"
              style={{ display: 'none' }}
              onClick={() => {
                handleTestRegister();
              }}
            >
              Register now
            </button> */}
          </CCol>
        </CRow>
      </CContainer>
    </>
  );
};
export default Step2;
